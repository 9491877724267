/**
 * @description Checks the localstorage and shows Cookie banner based on it.
 */
function initializeCookieBanner() {
    var cookieBanner = document.getElementById("cookie-banner");
    var toast = new bootstrap.Toast(cookieBanner, { 'autohide': false });

    cookieBanner.addEventListener('hidden.bs.toast', function () {
        localStorage.setItem("cb_isCookieAccepted", "yes");
    })

    let isCookieAccepted = localStorage.getItem("cb_isCookieAccepted");
    if (!isCookieAccepted) {
        toast.show();
    }
}

// Assigning values to window object
window.onload = initializeCookieBanner();
